import React from "react"
import { Col, Row } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import Form from "../components/Forms/GeneralContact"
import ManagerInfo from "../components/ManagerInfo/ManagerInfo"
import BlogReel from "../components/BlogReel/BlogReel"

const rowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    margin: `48px auto`,
    maxWidth: 1360,
  },
}

const detailsCol = {
  xs: 23,
  md: 10,
  lg: 9,
  style: {
    padding: 12,
    textAlign: `center`,
  },
}

const formColProps = {
  xs: {
    span: 23,
  },
  md: 10,
  lg: 9,
  style: {
    padding: 12,
  },
}

const formProps = id => {
  return {
    formName: "General Contact",
    automationId: id,
    gtmClickId: "contact-page-form-button",
  }
}

export default ({ data }) => {
  const { hero, hotel } = data

  const meta = {
    seo: {
      title: "Contact | Lakeside Lodge Clemson",
      img: hero.localFile.childImageSharp.fluid.src,
      imgAlt: hero.description,
    },
    uri: "/contact",
  }

  const heroProps = {
    caption: `Get In Touch`,
    contentfulAsset: hero,
    height: `30vh`,
    imageStyle: { filter: `brightness(0.65)` },
  }

  const managerInfoProps = {
    propertyName: `Lakeside Lodge Clemson`,
    description: hotel.description.description,
    manager: {
      name: `Southern Resort Group`,
      url: `https://southernresortgroup.com`,
      streetNumber: `13500 Clemson Blvd`,
      city: `Seneca`,
      state: `SC`,
      zip: `29678`,
    },
    phone1: {
      heading: `24hr Concierge`,
      number: 8887863648,
    },
    phone2: {
      heading: `Reservations`,
      number: hotel.phone,
    },
  }

  return (
    <Shell>
      <Meta {...meta} />
      <Hero {...heroProps} />
      <BookingBar />
      <Row {...rowProps}>
        <Col {...detailsCol}>
          <ManagerInfo {...managerInfoProps} />
        </Col>
        <Col {...formColProps}>
          <Form {...formProps(38)} />
        </Col>
      </Row>
      <BlogReel numPosts={3} />
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "LLC Sales Center" }) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 80, webpQuality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    hotel: contentfulHotel(name: { eq: "Lakeside Lodge Clemson" }) {
      description {
        description
      }
      phone
    }
  }
`
